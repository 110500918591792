import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { ColumnsDataObject } from './ColumnsDataObject';
import { ColumnsNameDataObject } from './ColumnsNameDataObject';
import { ContactOptionsObject } from './ContactOptionsObject';
import { MatrixCredits } from './MatrixCredits';

class ConfiguredColumn {
    isVisible: boolean;
    index: number;
    accessor: string;
    name: string;
}

@jsonObject()
export class GetBusinessIndustryMetaResponseDataObject {
    page: number | string;
    pages: number;
    perPage: number;
    total: number;
}

export class SupplierBadgeIndustryConfig {
    type: 'badge' | 'post' | 'banner';
    src: string;
    altText: string;
}

@jsonObject()
export class BuyerQuestionsConfig {
    @jsonMember(String)
    title?: string;

    @jsonMember(String)
    columnName?: string;

    @jsonMember(String)
    _id?: string;

    @jsonMember(String)
    questionSlug?: string;

    @jsonMember(String)
    answer?: string;
}

export const BuyerQuestionsConfigSerializer = new TypedJSON(BuyerQuestionsConfig);

@jsonObject()
export class GetBusinessIndustryResponseDataObject {
    @jsonMember(String)
    _id: string;

    @jsonMember(String)
    industry: string;

    @jsonMember(String)
    industryUrl: string;

    @jsonMember(String)
    centralIndustryId: string;

    @jsonMember(Number)
    leadCost: number | string;

    @jsonMember(String)
    createdAt: string;

    @jsonMember(String)
    updatedAt: string;

    json: object;

    @jsonMember(Number)
    __v: number;

    @jsonArrayMember(() => ColumnsDataObject)
    columns: ColumnsDataObject[];

    @jsonArrayMember(() => ColumnsNameDataObject)
    columnsNames: ColumnsNameDataObject[];

    @jsonArrayMember(() => ContactOptionsObject)
    contactOptions: ContactOptionsObject[];

    @jsonMember(Boolean)
    isActive: boolean;

    @jsonMember(String)
    associatedCurrency: string;

    @jsonMember(String)
    country: string;

    matrix: MatrixCredits;

    @jsonMember(Number)
    avgConversionRate: number;

    @jsonMember(Number)
    minimumTopupLeads?: number;

    @jsonArrayMember(() => SupplierBadgeIndustryConfig)
    supplierBadges: SupplierBadgeIndustryConfig[];

    @jsonArrayMember(() => BuyerQuestionsConfig)
    buyerQuestions?: BuyerQuestionsConfig[];

    @jsonArrayMember(() => ColumnsDataObject)
    invisibleColumns?: ColumnsDataObject[];

    get columnsConfig(): ConfiguredColumn[] {
        return this.columns
            .sort(({ index: indexA }, { index: indexB }) => indexB - indexA)
            .map((column) => {
                let configuredColumn: ConfiguredColumn = new ConfiguredColumn();

                configuredColumn.index = column.index;
                configuredColumn.isVisible = column.isVisible;
                configuredColumn.accessor = column.originalName; //this.columnsNames.find(c => c.renamedColumn === column.name)?.renamedColumn;
                configuredColumn.name = column.displayName;

                return configuredColumn;
            });
    }

    set columnsConfig(value: ConfiguredColumn[]) {
        this.columns = value
            .sort(({ index: indexA }, { index: indexB }) => indexB - indexA)
            .map((cc) => {
                return {
                    isVisible: cc.isVisible,
                    originalName: cc.accessor,
                    displayName: cc.name,
                    index: cc.index,
                } as ColumnsDataObject;
            });

        // this.columnsNames = value.sort(({index: indexA}, {index: indexB}) => indexB - indexA).map(cc => {
        //     return {
        //         defaultColumn: cc.accessor,
        //         renamedColumn: cc.name
        //     } as ColumnsNameDataObject;
        // });
    }
}

export const BusinessBaseSerializer = new TypedJSON(GetBusinessIndustryResponseDataObject);
