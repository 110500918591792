import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Icon } from '@mui/material';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import React from 'react';
import './index.scss';

interface IReplacementContentProps {
    heading: string;
    content: string;
    scrollFunc?: () => void;
}

export const ReplacementContent: React.FC<IReplacementContentProps> = ({ heading, content, scrollFunc }) => {
    return (
        <section className="replacement-content-wrapper">
            <MDTypography
                variant="p"
                className="replacement-heading-1"
                dangerouslySetInnerHTML={{ __html: heading }}
            ></MDTypography>
            <MDTypography
                variant="p"
                className="replacement-content-1"
                dangerouslySetInnerHTML={{ __html: content }}
            ></MDTypography>
            <MDButton
                className="spotdif-animated-arrow-btn animated-arrow-dark replacement-btn"
                type="submit"
                onClick={scrollFunc}
            >
                <p>Get started</p>
                <Icon className="spotdif-arrow">
                    <ArrowForwardIcon />
                </Icon>
            </MDButton>
        </section>
    );
};
