import { MessageThread } from 'contracts/chat/MessageThread';
import dayjs from 'dayjs';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';

export class ParticipantObject {
    @jsonMember(String)
    _id: string;

    @jsonMember(String)
    userId: string;

    @jsonMember(String)
    businessName: string;

    @jsonMember(String)
    buyerDetailsId: string;

    @jsonMember(String)
    role: string;

    @jsonMember(String)
    displayName: string;

    @jsonMember(String)
    tag: string;
}

@jsonObject()
export class ChatRoom {
    @jsonMember(String, {
        deserializer: (value) => `${value}`,
    })
    leadId: string;

    @jsonMember(String)
    buyerId: string;

    @jsonMember(String)
    title: string;

    @jsonMember(String)
    cmsUsername: string;

    @jsonMember(String)
    buyerName: string;

    @jsonMember(String)
    buyerReference: string;

    @jsonMember(String)
    industryId: string;

    @jsonArrayMember(String)
    participants: string[];

    @jsonMember(String)
    queueId: string;

    @jsonArrayMember(ParticipantObject)
    participantsObj: ParticipantObject[];

    @jsonMember(String)
    status: string;

    // TODO: use enums in future
    // @jsonMember(() => ContactStatus, {
    //     deserializer: (v) => {
    //         switch (`${v}`.toLowerCase()) {
    //             case 'A':
    //                 return ContactStatus.unscheduled;

    //             case 'B':
    //             default:
    //                 return ContactStatus.standard;
    //         }
    //     },
    // })
    // status: ContactStatus;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    createdAt: dayjs.Dayjs;

    @jsonMember(() => dayjs.Dayjs, {
        deserializer: (value: any) => {
            return dayjs(value);
        },
    })
    updatedAt: dayjs.Dayjs;

    @jsonMember(String)
    _id: string;

    @jsonMember(() => MessageThread)
    latestMessage: MessageThread;

    get formattedTitle(): string {
        return [this.title, this.buyerName].filter(Boolean).join(' <> ');
    }

    get formattedTitle2(): string {
        const cmsParticipant = this.participantsObj?.find((participant) => participant.tag === 'cms');
        if (this.cmsUsername) {
            return this.cmsUsername;
        }
        if (cmsParticipant) {
            return cmsParticipant.displayName ? cmsParticipant.displayName : this.formattedTitle;
        } else {
            return this.formattedTitle;
        }
    }

    get formattedSubTitle(): string {
        // return this.latestMessage?.text ?? this.buyerReference;
        return this.latestMessage?.text;
    }

    get lastUpdatedAt(): dayjs.Dayjs {
        return this.latestMessage?.createdAt ?? this.updatedAt;
    }

    getParticipantDetails(id: string) {
        // const participant = this.participantsObj.find((participant) => participant._id === id);
        const participant = this.participantsObj.find((participant) => participant.userId === id);

        return (
            participant ?? {
                businessName: '',
                displayName: '',
                role: '',
                tag: '',
                _id: '',
            }
        );
    }
}

export const ChatRoomSerializer = new TypedJSON(ChatRoom);
