import { Grid, Paper, Typography } from '@mui/material';
import { ChatFileAttachmentsRenderer } from 'components/Inbox/ChatFileAttachmentsRenderer';
import HumanDateRenderer from 'components/shared/DateTimeRenderer/HumanDateRenderer';
import { MessageThread } from 'contracts/chat/MessageThread';
import useAuthentication from 'hooks/useAuthentication';
import React, { useEffect, useRef } from 'react';

interface IThreadItem {
    style: React.CSSProperties;
    message: MessageThread;
    index: number;
    senderDetails?: {
        role: string;
        displayName: string;
        businessName: string;
        tag: string;
        _id: string;
    } | null;
    setRowHeight: (arg1: number, arg2: number) => void;
}

const ThreadItem: React.FC<IThreadItem> = ({ senderDetails, style, message, index, setRowHeight }) => {
    const rowRef = useRef({});
    const { user } = useAuthentication();
    const debounceRef = useRef<number>();
    useEffect(() => {
        const lk = () => {
            if (rowRef.current) {
                // @ts-ignore
                setRowHeight(index, rowRef.current.clientHeight + 15);
            }
        };

        if (debounceRef.current) {
            clearTimeout(debounceRef.current);
        }

        debounceRef.current = window.setTimeout(lk, 200);

        return () => {
            if (debounceRef.current) {
                clearTimeout(debounceRef.current);
            }
        };
    }, [index, setRowHeight]);

    return (
        <div style={style}>
            {/* @ts-ignore */}
            <Grid ref={rowRef} item key={index} xs={12} className={`message-${message.direction.toLowerCase()}`}>
                <Paper className={`chat-thread-item ${message.media ? 'media' : 'paper-message'}`} elevation={0}>
                    {message.text && (
                        <>
                            {user?._id !== senderDetails?._id && (
                                <Typography variant="body2" className="message-owner">{`${
                                    senderDetails?.displayName
                                } (${senderDetails?.role ?? senderDetails?.tag})`}</Typography>
                            )}
                            <Typography
                                dangerouslySetInnerHTML={{ __html: parseUrls(message.text) }}
                                className={`message-text message-bg-${message.direction.toLowerCase()}`}
                                variant="body2"
                            >
                                {/* {message.text} */}
                            </Typography>
                        </>
                    )}
                    <ChatFileAttachmentsRenderer attachments={message.attachments} />
                </Paper>
            </Grid>

            <Typography className={`message-time-${message.direction.toLowerCase()}`}>
                <HumanDateRenderer date={message.createdAt} />
            </Typography>
        </div>
    );
};

export default ThreadItem;

function parseUrls(message) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return message.replace(urlRegex, ' <a href="$1" target="_blank">$1</a> ');
}
