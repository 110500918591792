import React, { useCallback } from 'react';
import { INotificationRequest } from 'contracts/requests/INotificationRequest';
import { Form, useFormikContext } from 'formik';
import MDInput from 'components/themed/MDInput';
import useAuthentication from 'hooks/useAuthentication';
import { useSendTestLeadMutation } from 'redux/services/spotdif/onBoarding';
import { enqueueSnackbar } from 'notistack';

const WebhookNotificationsForm: React.FC = () => {
    const { values, handleBlur, handleChange, handleSubmit, touched, errors } =
        useFormikContext<INotificationRequest>();
    const { user } = useAuthentication();
    const [sendTestLeadTrigger] = useSendTestLeadMutation();

    const sendTestLead = useCallback(
        (id: string, zapierUrl: string) => {
            const testLeadData = {
                _id: id,
                zapierUrl: zapierUrl,
            };
            sendTestLeadTrigger(testLeadData)
                .unwrap()
                .then((res) => {
                    return enqueueSnackbar(res?.data, { variant: 'success', key: 'send-test-lead' });
                })
                .catch((error) => {
                    return enqueueSnackbar(error?.data?.data ?? error?.data?.error?.message ?? 'Something went wrong', {
                        variant: 'error',
                        key: 'send-test-lead',
                    });
                });
        },
        [sendTestLeadTrigger],
    );

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <div className="tab-user-content-card payment-card">
                    <div className="full-width-field">
                        <MDInput
                            type="text"
                            label="Send a copy of your lead data to a webhook and then to your CRM by adding the URL below."
                            labelWebHookUrl="https://zapier.com/apps/webhook/integrations"
                            onBlur={handleBlur}
                            placeholder="Webhook URL"
                            id="zapierUrl"
                            name="zapierUrl"
                            value={values.zapierUrl}
                            onChange={handleChange}
                            error={touched.zapierUrl && Boolean(errors.zapierUrl)}
                            helperText={touched.zapierUrl && errors.zapierUrl}
                        />
                    </div>
                </div>
                <div className="d-flex">
                    <button type="submit" className="button">
                        Save changes
                    </button>
                    <button
                        type="button"
                        onClick={() => sendTestLead(user?._id, values.zapierUrl)}
                        className="button ml-1 button-outline-primary"
                    >
                        Send Test Lead
                    </button>
                </div>
            </Form>
        </>
    );
};

export default WebhookNotificationsForm;
