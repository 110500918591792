import { useEffect } from 'react';
import { useLazyGetPromoContentQuery } from 'redux/services/spotdif/promoLink';

export const usePromoLinkContent = (promoCode: string) => {
    const [getPromoContentTrigger, { data: promoContentData }] = useLazyGetPromoContentQuery();

    useEffect(() => {
        if (promoCode) {
            getPromoContentTrigger(promoCode);
        }
    }, [promoCode]);

    const {
        heroSection,
        leadShowCase,
        qualityLeads,
        replacementPolicyText,
        replacementPolicyHeader,
        badgeTitle,
        badgeSubTitle,
        badgeColor,
    } = promoContentData?.data || {};

    return {
        // heroSection: heroSection || '<p>Get high-intend leads for <span>free</span><p>',
        heroSection: heroSection || '<p>Get sales ready appointments <span>tomorrow</span><p>',
        leadShowCase:
            leadShowCase ||
            `<span class="semibold">Sign up today and join other business who are generating more work through SpotDif</span>`,
        badgeTitle: badgeTitle || null,
        badgeSubTitle: badgeSubTitle || null,
        badgeColor: badgeColor || null,
        qualityLeads:
            qualityLeads ||
            `Fully <strong> qualified</strong> leads <br/><strong>Speak</strong> to every customer<br/> Boost your <strong> business</strong> today `,
        replacementPolicyHeader: replacementPolicyHeader || null,
        replacementPolicyText: replacementPolicyText || null,
    };
};
