import { IUserUpdateRequest } from 'contracts/requests/IUserUpdateRequest';
import { OnboardingStepValidationInfo } from 'contracts/spotdif/OnboardingStepValidationInfo';
import { jsonArrayMember, jsonMember, jsonObject, TypedJSON } from 'typedjson';
import { EUserOnboardingPercentage } from '../app-utils/EUserOnBoardingPercentage';
import { EUserRole } from '../app-utils/EUserRole';
import { BaseDataModel } from './BaseDataModel';
import { BusinessDetailsDataObject, BusinessDetailsDataObjectSerializer } from './BusinessDetailsDataObject';
import {
    BuyerQuestionsConfig,
    BuyerQuestionsConfigSerializer,
    GetBusinessIndustryResponseDataObject,
} from './GetBusinessIndustryResponseDataObject';
import { LeadDetails } from './LeadDetails';
import { ServiceDetailObject } from './ServiceDetailObject';
import { userPermissionsArray } from './userPermissionsArray';
import { ContactCreditsObject } from './ContactCredit';

@jsonObject()
export class UserBase extends BaseDataModel implements IUserUpdateRequest {
    @jsonMember(String)
    email: string;

    @jsonMember(String)
    firstName: string;

    @jsonMember(String)
    lastName: string;

    @jsonMember(String)
    phoneNumber?: string;

    @jsonMember(() => EUserRole, {
        deserializer: (value: string) => {
            switch (value.toLowerCase()) {
                case 'admin':
                    return EUserRole.ADMIN;
                case 'superadmin':
                    return EUserRole.SUPER_ADMIN;
                case 'nonbillable':
                    return EUserRole.NON_BILLABLE;
                case 'accountmanager':
                    return EUserRole.ACCOUNT_MANAGER;
                case 'accountadmin':
                    return EUserRole.ACCOUNT_ADMIN;
                case 'invited':
                    return EUserRole.INVITED;
                default:
                    return EUserRole.USER;
            }
        },
    })
    role: EUserRole;

    @jsonMember(Number)
    credits?: number;

    @jsonMember(() => ContactCreditsObject)
    contactCredits?: ContactCreditsObject;

    @jsonMember(String)
    manualDeliveryId?: string;

    @jsonMember(String)
    automaticDeliveryId?: string;
    
    @jsonMember(Number)
    spotdifInternalScore?: Number;

    @jsonMember(String)
    token?: string;

    @jsonMember(Boolean)
    hasEverTopped?: boolean;

    @jsonMember(String)
    accountManager?: string;

    //   @jsonMember(Boolean)
    //   isDefault?: boolean;

    //   @jsonMember(String)
    //   userId?: string;

    @jsonMember(String)
    ryftClientId?: string;

    @jsonMember(Boolean)
    isLeadCostCheck?: boolean;

    @jsonMember(Boolean)
    isLeadReceived?: boolean;

    @jsonMember(Boolean)
    promoCodeUsed?: boolean;

    @jsonMember(String)
    userNotes?: string;

    @jsonMember(String)
    paymentMethod?: string;

    @jsonMember(String)
    invitedById?: string;

    @jsonMember(() => BusinessDetailsDataObject, {
        deserializer: (value: any) => {
            if (value) {
                return BusinessDetailsDataObjectSerializer.parse(value);
            } else {
                return new BusinessDetailsDataObject();
            }
        },
    })
    businessDetailsId?: BusinessDetailsDataObject;

    @jsonMember(ServiceDetailObject)
    userServiceId?: ServiceDetailObject;

    @jsonMember(String)
    businessIndustryId?: string;

    @jsonMember(GetBusinessIndustryResponseDataObject)
    businessIndustry: GetBusinessIndustryResponseDataObject;

    @jsonMember(() => LeadDetails)
    userLeadsDetailsId?: LeadDetails;

    @jsonMember(Boolean)
    isArchived?: boolean;

    @jsonMember(Number, {
        deserializer: (value: any) => {
            // check if value is number or else convert to number
            return value ? Number(value) : 0;
        },
    })
    rowIndex?: number;

    @jsonMember(Boolean)
    autoCharge?: boolean;

    @jsonMember(Number)
    leadCost?: number;

    @jsonMember(Number)
    secondaryLeads?: number;

    @jsonMember(Number)
    secondaryCredits?: number;

    @jsonMember(Number)
    secondaryLeadCost?: number;

    @jsonMember(Boolean)
    isSecondaryUsage?: boolean;
    
    @jsonMember(Boolean)
    isNationalNonBillable?: boolean;

    @jsonMember(Boolean)
    isRyftCustomer?: boolean;

    @jsonMember(Boolean)
    isLeadbyteCustomer?: boolean;

    @jsonMember(Number)
    avgConversionRate?: number;

    @jsonMember(Number)
    minimumTopupLeads?: number;

    @jsonMember(String, {
        deserializer: (value: any) => {
            // check if value is string or else convert to string
            return value ? value : null;
        },
    })
    verifiedAt: string;

    @jsonMember(Boolean)
    isVerified: boolean;

    @jsonMember(Boolean)
    isActive: boolean;

    @jsonMember(Boolean)
    showImpersonate: boolean;

    @jsonMember(Boolean)
    isUserSignup: boolean;

    @jsonMember(Boolean)
    registrationMailSentToAdmin: boolean;

    @jsonMember(String, {
        deserializer: (value: any) => {
            // check if value is string or else convert to string
            return value ? value : null;
        },
    })
    activatedAt: string;

    @jsonMember(String)
    buyerId?: string;

    //   @jsonMember(String)
    //   delivery?: string;
    @jsonMember(Boolean, {
        deserializer: (value: unknown) => {
            // check if value is boolean or else convert to boolean
            return value ? !!value : false;
        },
    })
    isCommissionedUser: boolean;

    @jsonArrayMember(() => OnboardingStepValidationInfo)
    onBoarding: OnboardingStepValidationInfo[];

    @jsonArrayMember(() => userPermissionsArray)
    permissions: userPermissionsArray[];

    @jsonArrayMember(() => BuyerQuestionsConfig, {
        deserializer: (value: unknown) => {
            if (value) {
                let values = null;

                try {
                    values = (value as Array<unknown>).map((v) => BuyerQuestionsConfigSerializer.parse(v));
                } catch (ex) {
                    console.error(ex);
                }

                return values;
            } else {
                return null;
            }
        },
    })
    buyerQuestions: BuyerQuestionsConfig[];

    @jsonMember(String)
    premiumUser?: string;

    @jsonMember(String)
    promoLinkId?: string;

    @jsonMember(String)
    buisnessIndustryId?: string;

    @jsonMember(String)
    clientNotes?: string;

    @jsonMember(String)
    smsPhoneNumber?: string;

    @jsonMember(Boolean)
    isSmsNotificationActive?: boolean;

    @jsonMember(String)
    businessName?: string;

    @jsonMember(Boolean)
    isCreditsAndBillingEnabled?: boolean;

    @jsonMember(String)
    stripeClientId: string;

    @jsonMember(Number)
    onBoardingPercentage?: number;

    @jsonMember(Boolean)
    isNewUser?: boolean;

    @jsonMember(Boolean)
    isAutoChargeEnabled?: boolean;

    @jsonMember(String)
    message?: string;

    @jsonMember(String)
    currency?: string;

    @jsonMember(Boolean)
    isAccountAdmin?: boolean;

    @jsonMember(String)
    mobilePrefixCode?: string;

    @jsonMember(String)
    country?: string;

    @jsonMember(String)
    pendingTransaction: string;
    @jsonMember(Number, {
        deserializer: (value: any) => {
            // check if value is number or else convert to number
            return value ? Number(value) : 0;
        },
    })
    triggerAmount: number;

    @jsonMember(Number, {
        deserializer: (value: any) => {
            // check if value is number or else convert to number
            return value ? Number(value) : 0;
        },
    })
    autoChargeAmount?: number;

    get fullName(): string {
        if (!this.firstName && !this.lastName) {
            return '';
        } else if (!this.lastName) {
            return this.firstName;
        }

        return `${this.firstName} ${this.lastName}`.trim();
    }

    get isAdmin(): boolean {
        return this.role === EUserRole.ADMIN;
    }

    get isSuperAdmin(): boolean {
        return this.role === EUserRole.SUPER_ADMIN;
    }

    get isAccountManager(): boolean {
        return this.role === EUserRole.ACCOUNT_MANAGER;
    }

    get hasOnboarded(): boolean {
        return this.isUserSignup || this.onboardingPercentage === 100;
    }

    get normalizedTriggerAmount(): number {
        return this.leadCost > 0 ? Math.round(this?.triggerAmount / this?.leadCost) : 5;
    }

    get normalizedTopupAmount(): number {
        return this.leadCost > 0 ? Math.round(this?.autoChargeAmount / this?.leadCost) : 10;
    }

    get normalizedUserCredits(): number {
        return this.credits && this.leadCost > 0 ? Math.floor(this?.credits / this?.leadCost) : 0;
    }

    /*   Previous onboardingPercentage from Frontend, not working fine in LOST CLIENTS */
    // get onboardingPercentage(): number {
    //     if (
    //         (this.role === EUserRole.USER && this.isCreditsAndBillingEnabled) ||
    //         (this.role === EUserRole.NON_BILLABLE && this.isCreditsAndBillingEnabled)
    //     ) {
    //         const pending = this.onBoarding.filter((step) => {
    //             return step.pendingFields.length > 0;
    //         }).length;

    //         return 100 - pending * 25;
    //     } else if (
    //         (this.role === EUserRole.USER && !this.isCreditsAndBillingEnabled) ||
    //         (this.role === EUserRole.NON_BILLABLE && !this.isCreditsAndBillingEnabled)
    //     ) {
    //         const filteredOnBoarding = this.onBoarding.filter((step) => step.key !== 'cardDetails');

    //         const pending = filteredOnBoarding.filter((step) => {
    //             return step.pendingFields.length > 0;
    //         }).length;

    //         return pending === 2 ? 25 : pending === 1 ? 50 : pending === 0 ? 100 : 100 - pending * 25;
    //     }
    // }

    get onboardingPercentage(): number {
        if (this.role === EUserRole.INVITED) return 100; // since onboarding will be done by actual user before a user is invited.

        // Filter out undefined elements from onBoarding array
        const filteredOnboarding = this.onBoarding.filter((step) => step !== undefined);

        // Check if there are no valid steps in onBoarding
        if (filteredOnboarding.length === 0) {
            return 0;
        }

        // Proceed with the rest of the logic based on the filtered onBoarding array
        if (
            (this.role === EUserRole.USER && this.isCreditsAndBillingEnabled) ||
            (this.role === EUserRole.NON_BILLABLE && this.isCreditsAndBillingEnabled)
        ) {
            const pending = filteredOnboarding.filter((step) => step.pendingFields.length > 0).length;
            return 100 - pending * 25;
        } else if (
            (this.role === EUserRole.USER && !this.isCreditsAndBillingEnabled) ||
            (this.role === EUserRole.NON_BILLABLE && !this.isCreditsAndBillingEnabled)
        ) {
            const filteredOnBoardingWithoutCardDetails = filteredOnboarding.filter(
                (step) => step.key !== 'cardDetails',
            );
            const pending = filteredOnBoardingWithoutCardDetails.filter((step) => step.pendingFields.length > 0).length;
            return pending === 2 ? 25 : pending === 1 ? 50 : pending === 0 ? 100 : 100 - pending * 25;
        }

        return 100;
    }

    get onboardingRoute(): string {
        const config =
            (this.role === EUserRole.USER && this.isCreditsAndBillingEnabled) ||
            (this.role === EUserRole.NON_BILLABLE && this.isCreditsAndBillingEnabled)
                ? {
                      [EUserOnboardingPercentage.StepOne]: '/on-boarding/business',
                      [EUserOnboardingPercentage.StepTwo]: '/on-boarding/leads',
                      [EUserOnboardingPercentage.StepThree]: '/on-boarding/payment',
                  }
                : (this.role === EUserRole.USER && !this.isCreditsAndBillingEnabled) ||
                  (this.role === EUserRole.NON_BILLABLE && !this.isCreditsAndBillingEnabled)
                ? {
                      [EUserOnboardingPercentage.StepOne]: '/on-boarding/business',
                      [EUserOnboardingPercentage.StepTwo]: '/on-boarding/leads',
                  }
                : {};

        return config[this.onboardingPercentage];
    }

    get allowedOnboardingRoute(): string[] {
        const config =
            (this.role === EUserRole.USER && this.isCreditsAndBillingEnabled) ||
            (this.role === EUserRole.NON_BILLABLE && this.isCreditsAndBillingEnabled)
                ? {
                      [EUserOnboardingPercentage.StepOne]: ['/on-boarding', '/on-boarding/business'],
                      [EUserOnboardingPercentage.StepTwo]: [
                          '/on-boarding',
                          '/on-boarding/business',
                          '/on-boarding/leads',
                      ],
                      [EUserOnboardingPercentage.StepThree]: [
                          '/on-boarding',
                          '/on-boarding/business',
                          '/on-boarding/leads',
                          '/on-boarding/payment',
                      ],
                  }
                : (this.role === EUserRole.USER && !this.isCreditsAndBillingEnabled) ||
                  (this.role === EUserRole.NON_BILLABLE && !this.isCreditsAndBillingEnabled)
                ? {
                      [EUserOnboardingPercentage.StepOne]: ['/on-boarding', '/on-boarding/business'],
                      [EUserOnboardingPercentage.StepTwo]: [
                          '/on-boarding',
                          '/on-boarding/business',
                          '/on-boarding/leads',
                      ],
                  }
                : {};
        return config[this.onboardingPercentage];
    }

    isFinalOnboardingStep(stepPath: string): boolean {
        return (
            this.onboardingPercentage === 75 &&
            this.allowedOnboardingRoute.indexOf(stepPath) === this.allowedOnboardingRoute.length - 1
        );
    }

    isFirstAllowedStep(stepPath: string): boolean {
        return this.allowedOnboardingRoute[0] === stepPath;
        // return false;
    }

    isLastAllowedStep(stepPath: string): boolean {
        return false;
    }

    isStepCompleted(stepKey: string): boolean {
        return this.onBoarding.find(({ key }) => stepKey === key)?.pendingFields.length === 0;
    }

    getBusinessDetail(prop: string): string {
        return this.businessDetailsId.getValue(prop);
    }

    getUserLeadDetail(prop: string): string {
        return this.userLeadsDetailsId.getValue(prop);
    }

    canAccess(module: string, permission: string): boolean {
        return this.permissions.some(
            (userPermission) => userPermission.module === module && userPermission.permission.includes(permission),
        );
    }
}

export const UserBaseSerializer = new TypedJSON(UserBase);
