import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ClearIcon from '@mui/icons-material/Clear';

import ShortTextIcon from '@mui/icons-material/ShortText';
import { Icon } from '@mui/material';
import facebook from 'assets/svg/facebook.svg';
import instagram from 'assets/svg/instagram.svg';
import classNames from 'classnames';
import { SIGNINV2, SIGNUPV2, WELCOME } from 'components/layout/brandingLayout/CONST';
import React, { useState } from 'react';

import { Link } from 'react-router-dom';

import './BrandNav.scss';

interface IBrandNavProps {
    variant?: string;
    imageUrl?: string;
    trustworthy?: string;
    pathName?: string;
}

export const BrandNav: React.FC<IBrandNavProps> = ({ variant, imageUrl, trustworthy, pathName }) => {
    const [showResponsive, setShowResponsive] = useState(false);

    return (
        <div className="brand-nav-wrapper">
            <div
                className={classNames('nav-items', {
                    'nav-items-top': variant === 'header',
                    'nav-items-bottom': variant === 'footer',
                })}
            >
                <div className="logo-wrapper">
                    <img className="main-logo" src={imageUrl} alt="logo" />
                </div>
                <div className="nav-actions">
                    <div
                        className={classNames('trusworthy-box', {
                            'trusworthy-box-footer': variant === 'footer',
                        })}
                    >
                        {/* <img src={trustworthy} alt="trustpilotlogo" />
                        <Icon className="spotdif-arrow">
                            <ArrowForwardIcon />
                        </Icon> */}
                    </div>
                    {variant === 'header' && (
                        <>
                            {pathName === SIGNUPV2 || pathName === WELCOME ? (
                                <div className="sign-in">
                                    <Link to={SIGNINV2}>
                                        Sign in
                                        <Icon className="spotdif-arrow">
                                            <ArrowForwardIcon />
                                        </Icon>
                                    </Link>
                                </div>
                            ) : null}

                            <div className="create-account">
                                <Link to={SIGNUPV2}>
                                    Create an account
                                    <Icon className="spotdif-arrow ">
                                        <ArrowForwardIcon />
                                    </Icon>
                                </Link>
                            </div>
                            {showResponsive ? (
                                <div className="cross-menu" onClick={() => setShowResponsive(false)}>
                                    <ClearIcon />
                                </div>
                            ) : (
                                <div className="hamburger-menu" onClick={() => setShowResponsive(true)}>
                                    <ShortTextIcon />
                                </div>
                            )}
                        </>
                    )}
                    {variant === 'footer' && (
                        <>
                            <div className="terms ">
                                <Link
                                    to={'https://leads.spotdif.com/terms&conditions/Narwhal-Ts&Cs.pdf'}
                                    target="_blank"
                                >
                                    Terms & Conditions
                                    <Icon className="spotdif-arrow ">
                                        <ArrowForwardIcon />
                                    </Icon>
                                </Link>
                            </div>
                            <div className="privacy ">
                                <Link
                                    to={'https://leads.spotdif.com/terms&conditions/Narwhal-Ts&Cs.pdf'}
                                    target="_blank"
                                >
                                    Privacy Policy
                                    <Icon className="spotdif-arrow ">
                                        <ArrowForwardIcon />
                                    </Icon>
                                </Link>
                            </div>
                        </>
                    )}
                </div>
            </div>
            {showResponsive && (
                <div className="responsive-menu">
                    <div className="sign-in-responsive">
                        <Link to={SIGNINV2}>
                            Sign in
                            <Icon className="spotdif-arrow">
                                <ArrowForwardIcon />
                            </Icon>
                        </Link>
                    </div>
                    <div className="create-account-responsive">
                        <Link to={SIGNUPV2}>
                            Create an account
                            <Icon className="spotdif-arrow">
                                <ArrowForwardIcon />
                            </Icon>
                        </Link>
                    </div>
                    {/* <div className="trusworthy-box-responsive">
                        <img src={trustworthy} alt="" />
                        (1,345 reviews)
                        <Icon className="spotdif-arrow">
                            <ArrowForwardIcon />
                        </Icon>
                    </div> */}
                    <p className="border-for-socials"></p>
                    <div className="social-media">
                        <div className="social-media-icon">
                            <img src={facebook} alt="facebook" />
                        </div>
                        <div className="social-media-icon">
                            <img src={instagram} alt="instagram" />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
