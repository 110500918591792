import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import addIcon from 'assets/images/basic-icons/addIcon.svg';
import './style.scss';
import BasicModal from 'components/core/Modal';
import InviteUser from 'components/user-profile/AddUserModal';
import DeleteUser from 'components/user-profile/DeleteUserModal';
import { EUserRole } from 'contracts/app-utils/EUserRole';
import useAuthentication from 'hooks/useAuthentication';
import { useSnackbar } from 'notistack';
import React, { useMemo, useState } from 'react';
import {
    useDeleteInvitedUserMutation,
    useGetInvitedUserQuery,
    useInviteUserMutation,
} from 'redux/services/spotdif/onBoarding';
import { useCurrentUserDataQuery, useUserProfileDataQuery } from 'redux/services/spotdif/user';
import MDButton from 'components/themed/MDButton';

interface ITeamMembersProps {}

const TeamMembers: React.FC<ITeamMembersProps> = (props) => {
    const { user } = useAuthentication();
    const { enqueueSnackbar } = useSnackbar();

    const [open, setOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [id, setId] = useState(null);

    const { data } = useGetInvitedUserQuery();
    const [deleteInvitedUser] = useDeleteInvitedUserMutation();

    useUserProfileDataQuery(user?._id, {
        refetchOnMountOrArgChange: true,
        skip: !user?._id,
    });

    const [deleteConfirmationData, setDeleteConfirmationData] = useState({
        name: '',
        email: '',
    });

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleCloseDeleteModal = () => setDeleteModal(false);

    const openDeleteModal = (e, id) => {
        setId(id);
        setDeleteModal(true);
    };

    const handleUserDeleteBtn = async (val) => {
        val ? await deleteInvitedUser(id) : setDeleteModal(false);

        enqueueSnackbar('Deleted Successfully', { variant: 'success' });
        setDeleteModal(false);
    };
    const { data: userData } = useCurrentUserDataQuery();

    const isUserInvited = useMemo(() => {
        if (userData?.data?.role === EUserRole.INVITED) return true;
        else return false;
    }, [userData]);

    return (
        <>
            {!isUserInvited && (
                <div className={'team-members'}>
                    <div className="team-members-header-block">
                        <Typography variant="h6">Team Members</Typography>
                        <MDButton type="button" className="addTeamMembersBtn button" onClick={() => handleOpen()}>
                            <span className="add-plus-icon">+</span>
                            Add a team member
                            {/* <img src={addIcon} className="addTeamMemberIcon" alt="add icon" /> */}
                        </MDButton>
                    </div>
                    {/* spotdif-card */}
                    <div className="">
                        {data?.length > 0 && (
                            <div className="team-table-heading team-members-table-heading">
                                <div className="teamMemberCardContent">
                                    <p>First Name</p>
                                    <p>Last Name</p>
                                    <p>Email</p>
                                </div>
                                <div className="teamMemberCardContentMobile">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            paddingRight: '16.5px',
                                            borderRight: '0.5px solid #9FA1B3',
                                        }}
                                    >
                                        <p>First Name</p>
                                        &nbsp;
                                        <p>Last Name</p>
                                    </Box>
                                    <p
                                        style={{
                                            paddingLeft: '16.5px',
                                        }}
                                    >
                                        Email
                                    </p>
                                </div>
                            </div>
                        )}
                        {data?.map((val, index) => {
                            return (
                                <div className="teamMemberCard" key={index}>
                                    <div className="teamMemberCardContent">
                                        {val?.firstName?.length > 10 ? (
                                            <Tooltip title={val?.firstName} placement="top">
                                                <p>{val?.firstName}</p>
                                            </Tooltip>
                                        ) : (
                                            <p>{val?.firstName}</p>
                                        )}
                                        {val?.lastName?.length > 10 ? (
                                            <Tooltip title={val?.lastName} placement="top">
                                                <p>{val?.lastName}</p>
                                            </Tooltip>
                                        ) : (
                                            <p>{val?.lastName}</p>
                                        )}
                                        {val?.email?.length > 10 ? (
                                            <Tooltip title={val?.email} placement="top">
                                                <p className="team-member-email">{val?.email}</p>
                                            </Tooltip>
                                        ) : (
                                            <p>{val?.email}</p>
                                        )}
                                    </div>
                                    <div className="teamMemberCardContentMobile">
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                paddingRight: '16.5px',
                                                borderRight: '0.5px solid #9FA1B3',
                                            }}
                                        >
                                            {val?.firstName?.length > 10 ? (
                                                <Tooltip title={val?.firstName} placement="top">
                                                    <p>{val?.firstName}</p>
                                                </Tooltip>
                                            ) : (
                                                <p>{val?.firstName}</p>
                                            )}
                                            {val?.lastName?.length > 10 ? (
                                                <Tooltip title={val?.lastName} placement="top">
                                                    <p>{val?.lastName}</p>
                                                </Tooltip>
                                            ) : (
                                                <p>{val?.lastName}</p>
                                            )}
                                        </Box>
                                        {val?.email?.length > 10 ? (
                                            <Tooltip title={val?.email} placement="top">
                                                <p className="team-members-left-padding">{val?.email}</p>
                                            </Tooltip>
                                        ) : (
                                            <p className="team-members-left-padding">{val?.email}</p>
                                        )}
                                    </div>
                                    <div className="delete-icon" onClick={(e) => openDeleteModal(e, val._id)}>
                                        <DeleteIcon />
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <BasicModal
                        // width={600} height={440}
                        open={open}
                        handleClose={handleClose}
                    >
                        <InviteUser
                            onModalClose={handleClose}
                            useSaveMutation={useInviteUserMutation}
                            modalHeader="Add a new team member"
                            modalButton="Invite Member"
                        />
                    </BasicModal>

                    <BasicModal open={deleteModal} handleClose={() => setDeleteModal(false)}>
                        <DeleteUser
                            handleUserDeleteBtn={handleUserDeleteBtn}
                            handleCloseDeleteModal={handleCloseDeleteModal}
                            userData={deleteConfirmationData}
                        />
                    </BasicModal>
                </div>
            )}
        </>
    );
};

export default TeamMembers;
