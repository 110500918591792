import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Checkbox, FormControlLabel, InputAdornment, MenuItem, Select } from '@mui/material';
import Icon from '@mui/material/Icon';
import joinhands from 'assets/images/icons/join-hands.svg';
// import signUpmain from 'assets/images/sign-up-main.svg';
import signUpmain from 'assets/images/sign-up-new.svg';
import checkMarkImage from 'assets/svg/check-solid.svg';
import './style.scss';
import { PromoBadge } from 'components/shared/PromoBadge';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDInput from 'components/themed/MDInput';
import MDTypography from 'components/themed/MDTypography';
import { Form, FormikProvider, useFormik } from 'formik';
import { usePromoLinkContent } from 'hooks/usePromoLinkContent';
import { useRegexPatterns } from 'hooks/useRegexPatterns';
import { useSnackbar } from 'notistack';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useSearchParams } from 'react-router-dom';
import { useGetMobilePrefixQuery, useRegisterUserMutation } from 'redux/services/spotdif';
import handleKeyPress from 'utils/validation';
import * as Yup from 'yup';

const SignUp: React.FC = () => {
    const isFirstSaleAssured = false;

    const [registerUser, { isLoading }] = useRegisterUserMutation();
    const { data: mobilePrefixList } = useGetMobilePrefixQuery();
    const { enqueueSnackbar } = useSnackbar();

    const [searchParams] = useSearchParams();

    const { badgeColor, badgeSubTitle, badgeTitle, heroSection } = usePromoLinkContent(searchParams.get('code'));

    const { emailRegex, passwordRegex, phoneNumberRegex } = useRegexPatterns();

    // --reCaptchaRef ref
    const reCaptchaRef = useRef(null);

    const initialValues = {
        email: '',
        password: '',
        confirmPassword: '',
        firstName: '',
        lastName: '',
        phoneNumber: '',
        termAndCondition: false,
        ...(searchParams.get('code') ? { code: searchParams.get('code') } : {}),
        recaptcha: '',
        mobilePrefixCode: '44',
    };

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required(' Email required *')
            .email('Must be a valid email')
            .matches(emailRegex, 'Enter a valid email'),
        password: Yup.string()
            .required(' Password required *')
            .matches(
                passwordRegex,
                'Password must be of 8 Characters, must contain one uppercase, one lowercase, one numeric character',
            ),
        firstName: Yup.string().required(' First Name required *').min(3, 'minimum 3 characters'),
        lastName: Yup.string().required(' Last Name required *').min(3, 'minimum 3 characters'),
        phoneNumber: Yup.string()
            .required(' Phone Number is required *')
            .matches(phoneNumberRegex, 'Phone Number is not valid'),
        confirmPassword: Yup.string()
            .trim()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required *')
            .min(3)
            .matches(/^\S*$/, 'Password cannot be all whitespace'),
        termAndCondition: Yup.boolean()
            .oneOf([true], 'Please accept Terms & Conditions')
            .required('Please accept Terms & Conditions'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            try {
                // Validate the form values using the validation schema
                await validationSchema.validate(values, { abortEarly: false });
            } catch (errors) {
                // If there are validation errors, set the form errors
                formik.setErrors(errors.inner.reduce((acc, error) => ({ ...acc, [error.path]: error.message }), {}));
                return;
            }

            try {
                // Get the reCAPTCHA token
                const recaptchaToken = await reCaptchaRef.current.executeAsync();
                formik.setFieldValue('recaptcha', recaptchaToken);
                // Reset the reCAPTCHA token for next time
                reCaptchaRef.current.reset();
                // Register the user with the form values and reCAPTCHA token
                await registerUser({ ...values, recaptcha: recaptchaToken })
                    .unwrap()
                    .then((data) => {
                        // Show a success snackbar message
                        return enqueueSnackbar('Welcome', { variant: 'success', key: 'user-auth' });
                    })
                    .catch((err) => {
                        // Show an error snackbar message with the error message from the server, or a default message
                        return enqueueSnackbar(err?.data?.data?.message || 'Something went wrong', {
                            variant: 'error',
                            key: 'user-auth',
                        });
                    });
            } catch (error) {
                // Handle errors while getting the reCAPTCHA token
                console.error('Error while getting reCAPTCHA token:', error);
                return;
            }
        },
    });

    return (
        <>
            <Helmet>
                <title>SpotDif</title>
                <meta name="robots" content="noindex, nofollow" />
                <link rel="canonical" href={`${process.env.REACT_APP_WEBSITE_URL}/sign-up`} />
            </Helmet>
            <div className="signup-wrapper-new">
                <div className="left-wrapper">
                    {badgeColor && badgeSubTitle && badgeTitle ? (
                        <PromoBadge badgeColor={badgeColor} badgeSubTitle={badgeSubTitle} badgeTitle={badgeTitle} />
                    ) : null}
                    {
                        isFirstSaleAssured && (
                            <div className="title-wrapper">
                                <img src={joinhands} alt="" className="join-hands" />
                                First Sale Guaranteed
                            </div>
                        )
                    }
                    <h1 className="signup-heading" dangerouslySetInnerHTML={{ __html: heroSection }}></h1>

                    {
                        isFirstSaleAssured && (
                            <p className="signup-para">
                                Purchase your leads with confidence. If no sale is made in your first batch of leads, we’ll
                                continue to deliver at <b>no extra cost</b> until your next customer is secured.*
                            </p>
                        )
                    }

                    <p className="signup-terms-conditions">*Terms & Conditions apply </p>
                </div>
                <img src={signUpmain} alt="" className="sign-up-main" />
                <div className="right-wrapper">
                    <h3 className="signup-heading-two">Sign up for free</h3>
                    <div className="signup-features">
                        <p className="signup-feature-text">
                            <img src={checkMarkImage} alt="checkMark" className="checkMarkImage" /> Pay-as-you-go
                        </p>
                        <p className="signup-feature-text two">
                            <img src={checkMarkImage} alt="checkMark" className="checkMarkImage" /> No fees
                        </p>
                    </div>
                    <FormikProvider value={formik}>
                        <Form onSubmit={formik.handleSubmit} className="resposnive-form">
                            <MDBox my={3} mx={'auto'} className="spotdif-textbox-flex">
                                <MDInput
                                    type="text"
                                    label="First Name"
                                    sx={{ width: 1 / 2 }}
                                    className="spotdif-field spotdif-field-dark spotdif-field-float"
                                    value={formik.values.firstName}
                                    id="firstName"
                                    name="firstName"
                                    isBordered={false}
                                    onBlur={formik.handleBlur}
                                    autoComplete="firstName"
                                    onChange={(e) => formik.setFieldValue('firstName', e.target.value.trim())}
                                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                    helperText={formik.touched.firstName && formik.errors.firstName}
                                />
                                <MDInput
                                    type="text"
                                    label="Last Name"
                                    sx={{ width: 1 / 2 }}
                                    className="spotdif-field spotdif-field-dark spotdif-field-float"
                                    value={formik.values.lastName}
                                    id="lastName"
                                    name="lastName"
                                    isBordered={false}
                                    onBlur={formik.handleBlur}
                                    autoComplete="lastName"
                                    onChange={(e) => formik.setFieldValue('lastName', e.target.value.trim())}
                                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                    helperText={formik.touched.lastName && formik.errors.lastName}
                                />
                            </MDBox>
                            <MDBox my={3} mx={'auto'} className="spotdif-textbox-flex">
                                <MDInput
                                    type="email"
                                    label="Email"
                                    name="email"
                                    sx={{ width: 1 / 2 }}
                                    onBlur={formik.handleBlur}
                                    isBordered={false}
                                    className="spotdif-field spotdif-field-dark spotdif-field-float"
                                    value={formik.values.email}
                                    id="email"
                                    autoComplete="email"
                                    onChange={(e) => formik.setFieldValue('email', e.target.value.trim())}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                />
                                <MDInput
                                    type="tel"
                                    label="Phone Number"
                                    className="spotdif-field spotdif-field-dark spotdif-field-float"
                                    placeholder="Phone"
                                    value={formik.values.phoneNumber}
                                    id="phoneNumber"
                                    isBordered={false}
                                    onKeyPress={handleKeyPress}
                                    name="phoneNumber"
                                    sx={{ width: 1 / 2 }}
                                    onBlur={formik.handleBlur}
                                    autoComplete="phoneNumber"
                                    onChange={(e) => formik.setFieldValue('phoneNumber', e.target.value.trim())}
                                    error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
                                    helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Select
                                                    value={formik.values.mobilePrefixCode}
                                                    onChange={(e) =>
                                                        formik.setFieldValue('mobilePrefixCode', e.target.value)
                                                    }
                                                    MenuProps={{
                                                        style: {
                                                            maxHeight: '300px',
                                                        },
                                                        anchorOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                        transformOrigin: {
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        },
                                                    }}
                                                    className="input-adornment-select-mobile-prefix"
                                                    renderValue={(selected) => {
                                                        const selectedCountry = mobilePrefixList?.find(
                                                            (country) => country?.mobilePrefixCode === selected,
                                                        );
                                                        return selectedCountry
                                                            ? `+${selectedCountry?.mobilePrefixCode}`
                                                            : '';
                                                    }}
                                                >
                                                    {mobilePrefixList?.map((country) => (
                                                        <MenuItem
                                                            key={country?.label}
                                                            value={country?.mobilePrefixCode}
                                                            autoFocus={
                                                                formik.values.mobilePrefixCode ===
                                                                country?.mobilePrefixCode
                                                            }
                                                        >
                                                            {`+${country?.mobilePrefixCode} - ${country?.label}`}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MDBox>
                            <MDBox my={3} mx={'auto'} className="spotdif-textbox-flex">
                                <MDInput
                                    type="password"
                                    label="Password"
                                    name="password"
                                    sx={{ width: 1 / 2 }}
                                    onBlur={formik.handleBlur}
                                    isBordered={false}
                                    className="spotdif-field spotdif-field-dark spotdif-field-float"
                                    value={formik.values.password}
                                    id="password"
                                    autoComplete="password"
                                    onChange={(e) => formik.setFieldValue('password', e.target.value.trim())}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                />

                                <MDInput
                                    type="password"
                                    name="confirmPassword"
                                    isBordered={false}
                                    sx={{ width: 1 / 2 }}
                                    onBlur={formik.handleBlur}
                                    label="Confirm Password"
                                    className="spotdif-field spotdif-field-dark spotdif-field-float"
                                    value={formik.values.confirmPassword}
                                    id="confirmPassword"
                                    autoComplete="confirmPassword"
                                    onChange={(e) => formik.setFieldValue('confirmPassword', e.target.value.trim())}
                                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                                />
                            </MDBox>

                            {formik.values.password.length ? (
                                <MDBox mb={3} className="spotdif-textbox-flex">
                                    <PasswordStrengthBar
                                        password={formik.values.password}
                                        onChangeScore={(score, feedback) => {
                                        }}
                                        shortScoreWord={false}
                                    />
                                </MDBox>
                            ) : (
                                ''
                            )}

                            <MDBox display="flex" alignItems="center" ml={-1} mt={2} className="terms-and-conditions">
                                <FormControlLabel
                                    className="spotdif-condition-field"
                                    control={
                                        <Checkbox
                                            className="checkbox-border"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.termAndCondition}
                                            name="termAndCondition"
                                            id="termAndCondition"
                                        />
                                    }
                                    label={
                                        <>
                                            <MDTypography
                                                variant="button"
                                                fontWeight="regular"
                                                color="text"
                                                sx={{
                                                    cursor: 'pointer',
                                                    userSelect: 'none',
                                                    borderRadius: '50px',
                                                    ml: -1,
                                                }}
                                            >
                                                &nbsp;&nbsp;I accept the&nbsp;
                                            </MDTypography>

                                            <MDTypography
                                                component="a"
                                                href="https://leads.spotdif.com/terms&conditions/Narwhal-Ts&Cs.pdf"
                                                target="_blank"
                                                variant="button"
                                                fontWeight="bold"
                                                color="white"
                                                // textGradient
                                            >
                                                Terms & Conditions
                                            </MDTypography>
                                        </>
                                    }
                                />
                            </MDBox>

                            {formik.touched.termAndCondition && formik.errors.termAndCondition && (
                                <span className="spotdif-custom-error-message">{formik.errors.termAndCondition}</span>
                            )}
                            <div className="recaptcha-wrapper">
                                <ReCAPTCHA
                                    ref={reCaptchaRef}
                                    sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                                    onChange={(value) => formik.setFieldValue('recaptcha', value)}
                                    onBlur={formik.handleBlur}
                                    size="invisible"
                                />
                                {formik.touched.recaptcha && formik.errors.recaptcha && (
                                    <span className="spotdif-custom-error-message">{formik.errors.recaptcha}</span>
                                )}
                            </div>
                            <MDBox>
                                <MDButton
                                    // className="spotdif-animated-arrow-btn"
                                    className="spotdif-signup-Button"
                                    type="submit"
                                    isLoading={isLoading}
                                    disabled={formik.isSubmitting}
                                >
                                    <p>Get started </p>
                                    <Icon className="spotdif-arrow">
                                        <ArrowForwardIcon />
                                    </Icon>
                                </MDButton>
                            </MDBox>
                        </Form>
                    </FormikProvider>
                </div>
            </div>
        </>
    );
};

export default SignUp;
