import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Icon } from '@mui/material';
// import greenArrow from 'assets/images/icons/arrow-green.svg';
import skyBlueArrow from 'assets/images/icons/sky-blue-arrow.svg';
import classNames from 'classnames';
import MDButton from 'components/themed/MDButton';
import React, { useMemo } from 'react';
import './index.scss';

interface IBrandPanelProps {
    variant?: string;
    title?: string;
    details?: string[];
    imageUrl?: string;
    paragraph?: string;
    scrollFunc?: () => void;
}

export const BrandPanel: React.FC<IBrandPanelProps> = ({
    variant,
    title,
    details,
    imageUrl,
    paragraph,
    scrollFunc,
}) => {
    const titleWithoutPTags = useMemo(() => {
        return title.replace(/^<p>|<\/p>$/g, '');
    }, [title]);

    return (
        <div
            className={classNames('brand-panel-wrapper', {
                'brand-panel-wrapper-white': variant === 'white',
                'brand-panel-wrapper-blue': variant === 'blue',
            })}
        >
            <div className="brand-panel-wrapper-left">
                <p className="brand-panel-title" dangerouslySetInnerHTML={{ __html: titleWithoutPTags }}></p>
                {/* <p className="brand-panel-border"></p> */}
                <p className="brand-panel-details-wrapper">
                    {details?.map((detail, index) => (
                        <span key={index} className="discover-details">
                            <img src={skyBlueArrow} alt="list-arrows" className="list-arrows" />{' '}
                            <p className="brand-panel-details" dangerouslySetInnerHTML={{ __html: detail }}>
                                {/* {detail} */}
                            </p>
                        </span>
                    ))}
                    {paragraph && <p className="brand-panel-details width-42ch">{paragraph}</p>}
                </p>
                {/* <MDButton className="spotdif-animated-arrow-btn animated-arrow-dark" type="submit" onClick={scrollFunc}>
                    <p>Get started</p>
                    <Icon className="spotdif-arrow">
                        <ArrowForwardIcon />
                    </Icon>
                </MDButton> */}
            </div>
            <div className="brand-panel-wrapper-right">
                <img src={imageUrl} alt="discover-panel" className="discover-image" />
            </div>
        </div>
    );
};
