import { Badge, Box, Drawer, ListItemAvatar } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import 'components/Inbox/style.scss';
import classNames from 'classnames';
import AuthorisedUsage from 'components/app-permission/AuthorisedUsage';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import LeadActions from 'components/Inbox/LeadActions';
import StringFormatter from 'components/shared/Text/StringFormatter';
import MDButton from 'components/themed/MDButton';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetChatRoomDetailsQuery } from 'redux/services/spotdif/chatToken';
import { selectChatRoomById } from 'redux/slices/chat';
import { useTypedSelector } from 'redux/store';
import ChatRoomDetails from './ChatRoomDetails';
import RateLead from './RateLead';

const ChatHeader = () => {
    const { roomId } = useParams();
    const currentRoom = useTypedSelector((state) => selectChatRoomById(state, roomId));

    const { leadId, isHydrated, isChatAllowed, isLoading, isError } = useGetChatRoomDetailsQuery(roomId, {
        selectFromResult: ({ data, isLoading, isError }) => {
            const hydrationStatus = data?.data?.isHydrated ?? false;
            return {
                leadId: data?.data?._id,
                isHydrated: hydrationStatus,
                isError: isError,
                isLoading: isLoading,
                isChatAllowed: (isLoading || !isError) && hydrationStatus,
            };
        },
    });

    const [isChatroomDetailsOpen, setIsChatRoomDetailsOpen] = useState(false);

    const updateChatRoomDetailsDrawer = useCallback((openFlag: boolean) => () => {
        setIsChatRoomDetailsOpen(openFlag);
    }, [setIsChatRoomDetailsOpen]);

    const explainLeadHydration = useCallback(() => {
        enqueueSnackbar(`The lead is not available for this account yet, please contact support.`, {
            variant: 'error',
            key: 'lead-hydration',
        });
    }, []);

    return currentRoom ? (
        <>
            <Box
                className="header-box"
                sx={{
                    boxShadow: 2,
                    width: 1,
                }}
            >
                <Grid container className="chat-header" xs={12} md={12}>
                    <Grid item xs={7} md={7} className={classNames('chat-user-info', { 'hide-right-border': isError })}>
                        <ListItemAvatar className="avatar-outer">
                            <Avatar alt={currentRoom?.formattedTitle} src="/static/images/avatar/2.jpg" />
                            <Badge
                                color="secondary"
                                className="online-user"
                                overlap="circular"
                                badgeContent=""
                                variant="dot"
                            ></Badge>
                        </ListItemAvatar>
                        <Typography className="user-name" variant="body1">
                            <StringFormatter
                                text={currentRoom?.formattedTitle2}
                                length={24}
                            />
                        </Typography>
                        <Typography variant="body2">#{currentRoom?.leadId}</Typography>
                        <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>
                            {
                                isChatAllowed ? (
                                    <MDButton sx={{ textTransform: 'capitalize' }} className="lead-details-btn"
                                              onClick={updateChatRoomDetailsDrawer(true)}>
                                        Lead Details
                                    </MDButton>
                                ) : null
                            }

                            <Drawer
                                anchor="right"
                                open={isChatroomDetailsOpen}
                                onClose={updateChatRoomDetailsDrawer(false)}
                                PaperProps={{
                                    style: {
                                        minWidth: 525, // Set your desired minimum width here
                                        padding: '0.75rem',
                                    },
                                }}
                            >
                                <ChatRoomDetails chatRoomId={roomId}
                                                 closeDetails={() => setIsChatRoomDetailsOpen(false)} />
                            </Drawer>
                        </AuthorisedUsage>
                    </Grid>
                    <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>
                        {isChatAllowed && <RateLead chatRoomId={roomId} />}
                    </AuthorisedUsage>
                    <AuthorisedUsage module={MODULE.CHAT_INBOX} permission={PERMISSIONS.UPDATE}>
                        {isChatAllowed && <LeadActions chatRoomId={roomId} />}
                    </AuthorisedUsage>
                    {
                        !isChatAllowed && (
                            <MDButton sx={{ textTransform: 'capitalize' }}
                                      onClick={explainLeadHydration}>
                                Chat Not Working?
                            </MDButton>
                        )
                    }
                </Grid>
            </Box>
        </>
    ) : null;
};

export default ChatHeader;
