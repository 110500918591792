import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import BusinessIcon from '@mui/icons-material/Business';
import ChatIcon from '@mui/icons-material/Chat';
import GroupsIcon from '@mui/icons-material/Groups';
import HelpIcon from '@mui/icons-material/Help';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import LockResetIcon from '@mui/icons-material/LockReset';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentIcon from '@mui/icons-material/Payment';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';

import './style.scss';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import accountIcon from 'assets/images/icons/account.svg';
import dashboardIcon from 'assets/images/icons/dashboard.svg';
import details from 'assets/images/icons/details.png';
import industriesIcon from 'assets/images/icons/industries.svg';
import menuIcon from 'assets/images/icons/menu-icon.png';

import logo from 'assets/svg/spotdif-logo-white-new.svg';
import classNames from 'classnames';
import { MODULE, PERMISSIONS } from 'components/app-permission/permission';
import Sidenav, { ISidebarRouteItem } from 'components/core/Sidenav';
import MDAlert from 'components/themed/MDAlert';
import MDBox from 'components/themed/MDBox';
import MDButton from 'components/themed/MDButton';
import MDTypography from 'components/themed/MDTypography';
import { IAppBanner, useApplicationUserContext } from 'context/ApplicationUser';
import { setMiniSidenav, useMaterialUIController } from 'context/ThemeContext';
import { useUserAnalytics } from 'context/UserAnalytics';
import useAuthentication from 'hooks/useAuthentication';
import usePageName from 'hooks/usePageName';
import React, { useEffect, useMemo, useState } from 'react';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { ADMIN_ONLY, CLIENTS_AS_WELL, CLIENTS_ONLY, SPECIAL_ADMINS_ONLY } from 'routing/ModuleProtectedRoute';
import { Badge } from '@mui/material';

interface IBaseLayoutProps {}

export const BaseLayout: React.FC<IBaseLayoutProps> = () => {
    const [controller, dispatch] = useMaterialUIController();
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [isHelpEnabled, setIsHelpEnabled] = useState(true);

    const currentPageHeading = usePageName();

    const { pathname } = useLocation();
    const { sendPageView } = useUserAnalytics();

    useEffect(() => {
        sendPageView(pathname, {
            title: currentPageHeading,
        });
    }, [currentPageHeading, pathname, sendPageView]);

    const { banners } = useApplicationUserContext();

    const {
        miniSidenav,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
        // direction,
        // layout,
        // openConfigurator,
    } = controller;
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    const { isAdmin, user, isUser, isNonBillableUser } = useAuthentication();

    const nestedUserAccountRoutes = useMemo<ISidebarRouteItem[]>(
        () => [
            {
                type: 'app-route',
                name: 'Your Details',
                key: 'info',
                route: '/account/info',
                icon: <img src={accountIcon} alt={'account'} loading="lazy" />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
                child: true,
            },
            {
                type: 'app-route',
                name: 'Your Business',
                key: 'business',
                route: '/account/business',
                icon: <BusinessIcon sx={{ color: '#ffffff' }} />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Lead Settings',
                key: 'leads',
                route: '/account/leads',
                icon: <SettingsSuggestIcon sx={{ color: '#ffffff' }} />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Credit and Billing',
                key: 'credit-and-billing',
                route: '/account/credit-and-billing',
                icon: <PaymentIcon sx={{ color: '#ffffff' }} />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_CREDIT_AND_BILLING,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Notifications',
                key: 'notifications',
                route: '/account/notifications',
                icon: <NotificationsIcon sx={{ color: '#ffffff' }} />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Reset Password',
                key: 'reset-password',
                route: '/account/reset-password',
                icon: <LockResetIcon sx={{ color: '#ffffff' }} />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
        ],
        [],
    );

    const filterNestedUserAccountRoutes =
        (isUser && user?.isCreditsAndBillingEnabled) || (isNonBillableUser && user?.isCreditsAndBillingEnabled)
            ? nestedUserAccountRoutes
            : nestedUserAccountRoutes.filter((route) => route.key !== 'credit-and-billing');

    const appRoutes = useMemo<ISidebarRouteItem[]>(() => {
        return [
            {
                type: 'app-route',
                name: 'Dashboard',
                key: 'dashboard',
                route: '/admin/dashboard',
                icon: <img src={dashboardIcon} alt={'dashboard'} loading="lazy" />,
                noCollapse: true,
                module: MODULE.DASHBOARD,
                permissions: [ADMIN_ONLY, SPECIAL_ADMINS_ONLY],
            },
            // {
            //     type: "app-route",
            //     name: "Clients",
            //     key: "clients",
            //     route: "/admin/clients",
            //     icon: <img src={details} alt={"userlist"} loading="lazy" />,
            //     noCollapse: true,
            //     permission: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN, EUserRole.ACCOUNT_MANAGER],
            //     module: MODULE.DASHBOARD,

            // },
            {
                type: 'app-route',
                name: 'Clients',
                key: 'clientsv2',
                route: '/admin/clients-v2',
                icon: <img src={details} alt={'userlist'} loading="lazy" />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENTS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            // {
            //     type: "app-route",
            //     name: "Leads",
            //     key: "leads",
            //     route: "/admin/leads",
            //     icon: <ThumbUpIcon />,
            //     noCollapse: true,
            //     permission: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN, EUserRole.ACCOUNT_MANAGER]
            // },
            {
                type: 'app-route',
                name: 'Leads',
                key: 'leads',
                route: '/admin/leads-v2',
                icon: <ThumbUpIcon />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,
                    {
                        module: MODULE.LEADS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            // {
            //     type: "app-route",
            //     name: "Reported Leads",
            //     key: "reported_leads",
            //     route: "/admin/reported-leads",
            //     icon: <ReportProblemIcon />,
            //     noCollapse: true,
            //     permission: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN]
            // },
            {
                type: 'app-route',
                name: 'Reported Leads',
                key: 'reported_leads_v2',
                route: '/admin/reported-leads-v2',
                icon: <ReportProblemIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.REPORTED_LEADS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            // {
            //     type: "app-route",
            //     name: "Business Industries",
            //     key: "industries_leads",
            //     route: "/admin/industries-leads",
            //     icon: <img src={industriesIcon} alt={"industriesleadcost"} loading="lazy" />,
            //     noCollapse: true,
            //     permission: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN]
            // },
            {
                type: 'app-route',
                name: 'Business Industries',
                key: 'industries_leads',
                route: '/admin/industries-leads-v2',
                icon: <img src={industriesIcon} alt={'industriesleadcost'} loading="lazy" />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,
                    SPECIAL_ADMINS_ONLY,
                    {
                        module: MODULE.BUSINESS_INDUSTRIES,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Account',
                key: 'account',
                route: '/admin/account',
                icon: <img src={accountIcon} alt={'account'} loading="lazy" />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,
                    {
                        module: MODULE.PROFILE,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            // {
            //     type: "app-route",
            //     name: "Promo Links",
            //     key: "promo_links",
            //     route: "/admin/promo-links",
            //     icon: <InsertLinkIcon />,
            //     noCollapse: true,
            //     permission: [EUserRole.ADMIN, EUserRole.SUPER_ADMIN]
            // },
            {
                type: 'app-route',
                name: 'Promo Links',
                key: 'promo_linksV2',
                route: '/admin/promo-links-v2',
                icon: <InsertLinkIcon />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,

                    {
                        module: MODULE.PROMO_LINKS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            // {
            //     type: "app-route",
            //     name: "Admins",
            //     key: "Admins",
            //     route: "/admin/sub-admin",
            //     icon: <AdminPanelSettingsIcon />,
            //     noCollapse: true,
            //     permission: [EUserRole.SUPER_ADMIN]
            // },
            {
                type: 'app-route',
                name: 'Admins',
                key: 'Admins',
                route: '/admin/sub-admin-v2',
                icon: <AdminPanelSettingsIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.ADMINS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Partners',
                key: 'Partners',
                route: '/admin/partners',
                icon: <GroupsIcon />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,

                    {
                        module: MODULE.PARTNERS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Post Codes',
                key: 'post_codes',
                route: '/admin/postcodes',
                icon: <MyLocationIcon />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,

                    {
                        module: MODULE.POST_CODE_STATS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },

            {
                type: 'app-route',
                name: 'Site Configs',
                key: 'site_configs',
                route: '/admin/site-configs',
                icon: <PermDataSettingIcon />,
                noCollapse: true,
                permissions: [
                    ADMIN_ONLY,
                    {
                        module: MODULE.SITE_CONFIGS,
                        permission: PERMISSIONS.UPDATE,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Advance Management',
                key: 'advance_management',
                route: '/admin/advance-management',
                icon: <GroupsIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.INVOICE_MANAGEMENT,
                        permission: PERMISSIONS.UPDATE,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Certified',
                key: 'certified',
                route: '/admin/certified',
                icon: <WorkspacePremiumIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.POST_CODE_STATS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Role Permissions',
                key: 'rolePermissions',
                route: '/admin/role-permissions',
                icon: <ManageAccountsIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.ROLE_PERMISSIONS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },

            // {
            //     type: "app-route",
            //     name: "Non Billable Clients",
            //     key: "non_billable_users",
            //     route: "/admin/non_billable_users",
            //     icon: <MoneyOffIcon />,
            //     noCollapse: true,
            //     permission: [EUserRole.SUPER_ADMIN]
            // },
            {
                type: 'app-route',
                name: 'Dashboard',
                key: 'dashboard',
                route: '/dashboard',
                icon: <img src={dashboardIcon} alt={'dashboard'} loading="lazy" />,
                noCollapse: true,
                permissions: [CLIENTS_ONLY, CLIENTS_AS_WELL],
            },
            // {
            //     type: "app-route",
            //     name: "Leads",
            //     key: "leads",
            //     route: "/leads",
            //     icon: <ThumbUpIcon />,
            //     noCollapse: true,
            //     permissions: [
            //     {
            //         module: MODULE.CLIENT_DASHBOARD,
            //         permission: PERMISSIONS.READ
            //     },
            //     ],
            // },
            {
                type: 'app-route',
                name: 'Leads',
                key: 'leads-v2',
                route: '/leads-v2',
                icon: <ThumbUpIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_LEADS,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'collapse',
                name: 'Account',
                key: 'account',
                route: '/account/info',
                icon: <img src={accountIcon} alt={'account'} loading="lazy" />,
                noCollapse: false,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                    {
                        module: MODULE.PROFILE,
                        permission: PERMISSIONS.READ,
                    },
                ],
                collapse: filterNestedUserAccountRoutes,
            },
            {
                type: 'app-button',
                name: 'Help',
                key: 'help-u',
                icon: <HelpIcon />,
                noCollapse: true,
                onClick: () => {
                    setIsHelpEnabled(!isHelpEnabled);
                    if (isHelpEnabled) {
                        isHelpEnabled && window.zE('messenger', 'show');
                        window.zE('messenger', 'open');
                    } else {
                        isHelpEnabled && window.zE('messenger', 'close');
                        window.zE('messenger', 'hide');
                    }
                },
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Organic Leads',
                key: 'planPackages',
                route: '/plans/packages',
                icon: <RocketLaunchIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Partners',
                key: 'Partners',
                route: '/partners/offers',
                icon: <GroupsIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_DASHBOARD,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Certified Supplier',
                key: 'Supplier',
                route: '/certified/supplier',
                icon: <WorkspacePremiumIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CLIENT_SUPPLIER_BADGE,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
            {
                type: 'app-route',
                name: 'Inbox',
                key: 'chat-inbox',
                route: '/chat-inbox',
                icon: <ChatIcon />,
                noCollapse: true,
                permissions: [
                    {
                        module: MODULE.CHAT_INBOX,
                        permission: PERMISSIONS.READ,
                    },
                ],
            },
        ].filter((navItem) => {
            return navItem.permissions.every((permission) => {
                return user.canAccess(permission.module, permission.permission);
            });
        });
    }, [filterNestedUserAccountRoutes, isHelpEnabled, user]);

    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

    return (
        <div
            className={classNames('app', 'spotdif-app', 'spotdif-full-dashboard-layout', {
                'spotdif-app--admin': isAdmin,
                'spotdif-app--user': !isAdmin,
            })}
            id="react-portal"
        >
            <ScrollRestoration />

            <Sidenav
                color={sidenavColor}
                // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brand={(transparentSidenav && !darkMode) || whiteSidenav ? logo : logo}
                brandName=""
                routes={appRoutes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />

            <MDBox
                sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                    p: 0,
                    position: 'relative',

                    [breakpoints.up('xl')]: {
                        marginLeft: miniSidenav ? pxToRem(120) : pxToRem(250),
                        transition: transitions.create(['margin-left', 'margin-right'], {
                            easing: transitions.easing.easeInOut,
                            duration: transitions.duration.standard,
                        }),
                    },
                })}
            >
                <div className={'application-banners'}>
                    {banners.content.map((banner: IAppBanner) => {
                        return (
                            <MDAlert
                                color={banner.type}
                                sx={{ width: 1 }}
                                key={banner.id}
                                className={classNames(
                                    'banner-container',
                                    `banner--${banner.type}`,
                                    `banner--${banner.id}`,
                                )}
                            >
                                <MDTypography className="banner-text">{banner.message}</MDTypography>
                                {banner.cta}
                            </MDAlert>
                        );
                    })}
                </div>

                <div className="layout-header">
                    <MDButton onClick={() => handleMiniSidenav()} className="toggle-sidebar-icon">
                        <img src={menuIcon} alt="toggle_icon" />
                    </MDButton>
                    {currentPageHeading === 'Inbox Alpha' ? (
                        <Badge
                            badgeContent={process.env.REACT_APP_VERSION}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        >
                            <MDTypography className="layout-main-heading">{currentPageHeading}</MDTypography>
                        </Badge>
                    ) : (
                        <MDTypography className="layout-main-heading">{currentPageHeading}</MDTypography>
                    )}
                </div>
                <Outlet />
            </MDBox>
            {/*<DriftChat/>*/}
        </div>
    );
};
