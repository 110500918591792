import { CopyAllOutlined } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import ReplayIcon from '@mui/icons-material/Replay';
import { ButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import addIcon from 'assets/images/basic-icons/addIcon.svg';
import DateRenderer from 'components/shared/DateTimeRenderer/DateRenderer';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import { ConfirmActionPopover } from 'components/shared/Popover/ConfirmActionPopover';
import SpinningComponent from 'components/shared/SpinningComponent';
import StringFormatter from 'components/shared/Text/StringFormatter';
import MDButton from 'components/themed/MDButton';
import { AuthKeySerializer } from 'contracts/spotdif/AuthKey';
import useAuthentication from 'hooks/useAuthentication';
import React, { useCallback } from 'react';
import {
    useDeleteUserAuthenticationKeyMutation,
    useGenerateAuthenticationKeysMutation,
    useGetUserAuthenticationKeysQuery,
} from 'redux/services/spotdif';
import { copyData } from 'utils/text-helpers';

interface IAuthenticationKeysProps {}

export const AuthenticationKeys: React.FC<IAuthenticationKeysProps> = () => {
    const { isAdmin, isSuperAdmin } = useAuthentication();

    const [generateAuthenticationKey, { isLoading: isRotatingKey }] = useGenerateAuthenticationKeysMutation();
    const [deleteAuthenticationKey] = useDeleteUserAuthenticationKeyMutation();
    const { isLoadingKeys, items } = useGetUserAuthenticationKeysQuery(undefined, {
        selectFromResult: ({ data, isLoading }) => {
            return {
                items: data?.data.map((v) => AuthKeySerializer.parse(v)) ?? [],
                isLoadingKeys: isLoading,
            };
        },
    });

    const triggerKeyRotation = useCallback(
        (keyId?: string | null) => {
            generateAuthenticationKey({
                _id: keyId,
            });
        },
        [generateAuthenticationKey],
    );

    const isFullyLoadedButEmpty = items?.length <= 0 && !isLoadingKeys && !isRotatingKey;
    const isAbleToCreateMoreKeys = isFullyLoadedButEmpty || isAdmin || isSuperAdmin;

    return (
        <ErrorBoundary identifier="authentication-keys" message="Something went wrong #sd-auth-keys--user">
            <Typography variant="h6">Authentication Keys</Typography>

            <div className="tab-user-content-card secondary-user-content-card">
                <div className="table-v2-block">
                    <div className="table-list-scroller">
                        <SpinningComponent loading={isLoadingKeys || isRotatingKey} isFullSized={false}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>Key</th>
                                        <th>Created At</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {items.map((authKey) => (
                                        <tr key={authKey._id}>
                                            <td>
                                                <StringFormatter text={authKey.key} />
                                                <MDButton
                                                    title="Copy Key"
                                                    onClick={() => copyData(authKey.key, 'Key copied.')}
                                                >
                                                    <CopyAllOutlined />
                                                </MDButton>
                                            </td>

                                            <td>
                                                <DateRenderer date={authKey.createdAt} />
                                            </td>

                                            <td>
                                                <ButtonGroup variant="outlined" color="secondary">
                                                    <ConfirmActionPopover
                                                        toolTipTitle={'Rotate Key'}
                                                        title={'This will update key to new value, continue?'}
                                                        onConfirm={() => triggerKeyRotation(authKey._id)}
                                                    >
                                                        <ReplayIcon className="replay-auth-icon" />
                                                    </ConfirmActionPopover>

                                                    <ConfirmActionPopover
                                                        toolTipTitle={'Delete'}
                                                        title={'Are you sure? You want to delete this key?'}
                                                        onConfirm={() => deleteAuthenticationKey(authKey._id)}
                                                    >
                                                        <DeleteIcon className='delete-auth-icon'/>
                                                    </ConfirmActionPopover>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </SpinningComponent>

                        {isAbleToCreateMoreKeys && (
                            <div className="addTeamMembersBtn" onClick={() => triggerKeyRotation()}>
                                Generate New Key
                                <img src={addIcon} className="addTeamMemberIcon" alt="add icon" />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    );
};
