import { useMediaQuery } from '@mui/material';
import ChatHeader from 'components/Inbox/ChatHeader';
import ChatHeaderMobile from 'components/Inbox/ChatHeaderMobile';
import Threads from 'components/Inbox/Threads';
import ErrorBoundary from 'components/shared/ErrorBoundary';
import React from 'react';

const Inbox: React.FC = () => {
    const isMobile = useMediaQuery('(max-width:768px)');
    return (
        <>
            <ErrorBoundary identifier="chat-inbox-header" message="Something went wrong #sd-chat-inbox--h">
                {isMobile ? <ChatHeaderMobile /> : <ChatHeader />}<></>
            </ErrorBoundary>
            <ErrorBoundary identifier="industries-expanded-row" message="Something went wrong #sd-chat-inbox--b">
                <Threads />
            </ErrorBoundary>
        </>
    );
};
export default Inbox;
